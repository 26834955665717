



.carousel-text-wrapper {
    display: flex;              /* Makes it a flex container */
    align-items: center;        /* Vertically aligns children in the center */
    justify-content: center;
    flex-direction: column;    /* Horizontally aligns children in the center */
}

.carousel-text-wrapper p {
    font-size: clamp(0.5rem, 5vw, 1.2rem);    
    margin: 0;
}


.carousel-text-wrapper div {
    flex-grow: 1;
}